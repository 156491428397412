import { flow } from 'lodash';
import format from 'date-fns/format';
import { takeEvery, put, select, delay, call, take } from 'redux-saga/effects';

import ROUTES from '@giro-pdv/constants/routes.constant';

import toaster from '@giro/shared-store/toaster';

import removeAcentoUtil from '@giro-pdv/utils/removeAcento.util';

import tablePayment from '../tablePayment';
import dialogPay from '../dialogs/dialogPay';

import { ACTION_TYPES } from './pinpad.constant';
import * as actions from './pinpad.action';
import * as selectors from './pinpad.selector';

import clients from '../clients';
import transactions from '../transactions';

import currentStore from '.';

const dicoveryLen = (str) => String(String(str).length).padStart(2, '0');

const converToStr = (obj) => {
  const arr = Object.keys(obj).map((o) => [o, obj[o].join('')].join(''));

  return arr.join('');
};

const fetchAgent = async (payload) => {
  const { url, type, data } = payload;

  const objResponse: any = {
    method: type,
  };

  if (type === 'post') {
    const datau = new URLSearchParams();

    objResponse.headers = {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    };

    Object.keys(data).map((d) => datau.append(d, data[d]));

    objResponse.body = datau;
  }

  const response = await fetch(url, objResponse);

  const result = await response.json();

  return [response.ok, result];
};

const handleServicePostStart = function* (action) {
  const detail = yield select(clients.selector.selectPayload);

  const typeTax = yield select(tablePayment.selector.selectTypeTax);
  const amountTable = yield select(tablePayment.selector.selectAmount);
  const transactionsState = yield select(transactions.selector.selectState);

  const installmentSelected = yield select(
    tablePayment.selector.selectInstallmentSelected
  );

  const amount =
    typeTax === 'ec' ? amountTable : installmentSelected.amount_number;

  const ret = [];
  const args: any = {};

  const funcao =
    installmentSelected.type === 'PIX'
      ? 122
      : installmentSelected.type === 'Débito'
      ? 2
      : 3; // 2 = Débito / 3 = Crédito / 122 = PIX;
  const continua = 0;

  const datenow = new Date();

  const cupomFiscal = transactionsState?.data?.meta;
  const dataFiscal = format(datenow, 'yyyyMMdd');
  const horaFiscal = format(datenow, 'hhmmss');
  const sitef = '127.0.0.1';
  const empresa = String(detail.idLoja);
  const terminal =
    String(detail.cdCliente).substring(0, 4) +
    String(detail.nuCPFCNPJ).substring(0, 4);
  const operador = 'PDV';
  const valor = amount.toFixed(2) * 100;
  const addParams = '';

  const descriptorMax22 = detail.descriptor.substring(0, 22);

  const dataSubObj = {
    '00': [dicoveryLen(descriptorMax22), removeAcentoUtil(descriptorMax22)],
    '01': [dicoveryLen(detail.endereco), removeAcentoUtil(detail.endereco)],
    '02': [dicoveryLen(detail.cidade), removeAcentoUtil(detail.cidade)],
    '03': [dicoveryLen(detail.uf), detail.uf],
    '04': ['03', ' BR'],
    '05': [dicoveryLen(detail.cep), detail.cep],
    '06': [dicoveryLen(detail.mcc), detail.mcc.padStart(4, '0')],
    '07': [dicoveryLen(detail.nuCPFCNPJ), detail.nuCPFCNPJ],
    '08': ['11', detail.nuTelefone.padStart(11, '0')],
    '09': [dicoveryLen(detail.tid), detail.tid],
    '10': [dicoveryLen(detail.email), detail.email],
    '11': [
      dicoveryLen(detail.nmRazaoSocial),
      removeAcentoUtil(detail.nmRazaoSocial),
    ],
    '12': ['01', detail.nuCPFCNPJ.length > 11 ? 'J' : 'F'],
    '13': ['15', detail.mid.padStart(15, '0')],
  };

  const paramsClient = `[ParmsClient=1=${detail.nuCPFCNPJ};2=23041219000134]`;
  const paramsSub = `[DadosSubAdquirencia=${converToStr(dataSubObj)}]`;
  const paramsPort = `[PortaPinPad=${detail.portaCom}]`;

  const initParams = [paramsPort, paramsClient, paramsSub].join(';');

  // Argumentos comuns
  args.functionId = funcao;
  args.trnAmount = Number(valor).toFixed(0);
  args.taxInvoiceNumber = cupomFiscal;
  args.taxInvoiceDate = dataFiscal;
  args.taxInvoiceTime = horaFiscal;
  args.cashierOperator = operador;
  args.trnAdditionalParameters = addParams;
  args.trnInitParameters = initParams;
  args.sitefIp = sitef;
  args.storeId = empresa;
  args.terminalId = terminal;

  const session = {
    ret,
    continua,
    cupomFiscal,
    dataFiscal,
    horaFiscal,
  };

  yield put(actions.updateSession(session));

  yield put(actions.updateTransactionStatus('transaction_init'));

  const request = {
    url: `${ROUTES.EXTERNAL.PINPAD}/startTransaction`,
    type: 'post',
    data: args,
  };

  try {
    const [success, data] = yield call(fetchAgent, request);

    if (!success) {
      throw data;
    }

    if (data.serviceStatus != 0) {
      yield put(
        actions.updateMessage(
          'Agente ocupado: ' + data.serviceStatus + ' ' + data.serviceMessage
        )
      );
    } else if (data.clisitefStatus != 10000) {
      yield put(
        actions.updateMessage('Retorno ' + data.clisitefStatus + ' da CliSiTef')
      );
    } else {
      const session = {
        continua: 0,
        sessionId: data.sessionId,
      };

      yield put(actions.updateSession(session));

      yield put(actions.servicePostContinue(''));
    }
  } catch (e: any) {
    yield put(transactions.action.servicePatch(11));

    yield put(currentStore.action.fetchError(e));

    yield put(toaster.action.show({ message: 'Error', variant: 'error' }));

    yield put(
      actions.updateMessage('Aconteceu um erro, tente novamente mais tarde.')
    );
  }
};

const handleServicePostContinue = function* (action) {
  const installmentSelected = yield select(
    tablePayment.selector.selectInstallmentSelected
  );

  const { payload } = action;

  const updateMessage = flow(actions.updateMessage, put);
  const updateSession = flow(actions.updateSession, put);
  const servicePostContinue = flow(actions.servicePostContinue, put);
  const servicePostFinish = flow(actions.servicePostFinish, put);
  const fetchSuccess = flow(actions.fetchSuccess, put);
  const fetchStart = flow(actions.fetchStart, put);
  const fetchError = flow(actions.fetchError, put);

  const session = yield select(selectors.selectSession);
  const { type_tax } = yield select(dialogPay.selector.selectPayload);

  const request = {
    url: `${ROUTES.EXTERNAL.PINPAD}/continueTransaction`,
    type: 'post',
    data: {
      sessionId: session.sessionId,
      data: payload,
      continue: session.continua,
    },
  };

  try {
    yield put(actions.updateTransactionStatus('transaction_processing'));

    yield fetchStart();

    const [success, data] = yield call(fetchAgent, request);

    if (!success) {
      throw data;
    }

    yield fetchSuccess(data);

    if (data.serviceStatus != 0) {
      yield put(dialogPay.action.close());

      return;
    }

    if (data.clisitefStatus != 10000) {
      if (data.clisitefStatus > 0) {
        yield put(transactions.action.servicePatch(10));

        yield put(
          actions.updateMessage(
            'Transação negada. Contate o emissor do cartão.'
          )
        );

        return;
      }

      if (data.clisitefStatus == 0) {
        return yield servicePostFinish();
      }

      yield put(transactions.action.servicePatch(10));

      yield put(actions.updateMessage('Transação não aprovada'));

      if (data.clisitefStatus === -2) {
        // Ao cancelar a operação
        yield put(dialogPay.action.close());
      }

      return;
    }

    switch (data.commandId) {
      case 0:
        yield updateSession({
          ret: [
            ...session.ret,
            {
              TipoCampo: data.fieldId,
              Valor: data.data,
            },
          ],
        });
        yield servicePostContinue('');
        break;

      case 3: {
        const isPix = installmentSelected.type === 'PIX';

        if (isPix) {
          const hasBlacklistMessage = String(data.data).includes(
            'Aguarde, em processamento'
          );

          if (hasBlacklistMessage) {
            yield servicePostContinue('');
            break;
          }
        }

        yield put(actions.updateMessage(data.data));
        yield servicePostContinue('');

        break;
      }
      // Messages
      case 1:
      case 2:
      case 4:
      case 11:
      case 12:
      case 13:
      case 14:
      case 15:
      case 16:
        yield put(actions.updateMessage(data.data));
        yield servicePostContinue('');
        break;

      // Informar se é á vista ou parcelado
      case 21:
        // 1 - A vista
        // 2 - Parcelado EC

        if (installmentSelected.installment > 1) {
          yield servicePostContinue('2');
        } else {
          yield servicePostContinue('1');
        }

        break;

      // No comando 23, faz o reset da flag de continuidade, para sensibilizar tratamento de confirma��es de cancelamento da clisitef.
      case 23:
        yield delay(500);

        yield servicePostContinue('');

        yield updateSession({
          continua: 0,
        });

        break;

      case 30:
        if (data.fieldId === 505) {
          yield servicePostContinue(installmentSelected.installment);
        }
        break;
      default:
        yield servicePostContinue('');
    }
  } catch (e: any) {
    yield fetchError(e);

    yield updateMessage('Erro: ' + e.status);
  }
};

const handleServicePostFinish = function* () {
  const session = yield select(selectors.selectSession);
  const transactionsState = yield select(transactions.selector.selectState);

  const fetchSuccess = flow(actions.fetchSuccess, put);
  const fetchStart = flow(actions.fetchStart, put);
  const fetchError = flow(actions.fetchError, put);

  const args = {
    confirm: 1,
    sessionId: session.sessionId,
    taxInvoiceNumber: transactionsState?.data?.meta,
    taxInvoiceDate: session.dataFiscal,
    taxInvoiceTime: session.horaFiscal,
  };

  const request = {
    url: `${ROUTES.EXTERNAL.PINPAD}/finishTransaction`,
    type: 'post',
    data: args,
  };

  try {
    yield fetchStart();

    const [success, data] = yield call(fetchAgent, request);

    if (!success) {
      throw data;
    }

    yield put(actions.updateMessage('Transação aprovada'));

    yield put(transactions.action.servicePatch(3)); // Aprovada

    yield fetchSuccess(data);
  } catch (e: any) {
    yield fetchError(e);
  }
};

const handleServicePostIsPresent = function* () {
  const handlers = {
    handlerUpdateStatus: flow(currentStore.action.updateStatus, put),
    handlerUpdatePresent: flow(currentStore.action.updatePresent, put),
    servicePostNewSession: flow(currentStore.action.servicePostNewSession, put),
    servicePostMessage: flow(currentStore.action.servicePostMessage, put),
    updateSession: flow(currentStore.action.updateSession, put),
  };

  yield handlers.servicePostNewSession();

  const { payload: payloadPinpad } = yield take(
    currentStore.constant.ACTION_TYPES.FETCH.SUCCESS
  );

  const { sessionId } = payloadPinpad;

  const args = {
    sessionId,
  };

  const request = {
    url: `${ROUTES.EXTERNAL.PINPAD}/pinpad/isPresent`,
    type: 'post',
    data: args,
  };

  try {
    yield handlers.handlerUpdateStatus('connecting');

    const [success, { clisitefStatus }] = yield call(fetchAgent, request);

    if (!success || clisitefStatus !== 1) {
      throw new Error('');
    }

    yield handlers.handlerUpdateStatus('connected');
    yield handlers.handlerUpdatePresent(true);
  } catch (e) {
    yield handlers.handlerUpdateStatus('error');
    yield handlers.handlerUpdatePresent(false);
  }
};

const handleServicePostNewSession = function* () {
  const detail = yield select(clients.selector.selectPayload);

  const handlers = {
    fetchStart: flow(currentStore.action.fetchStart, put),
    fetchError: flow(currentStore.action.fetchError, put),
    fetchSuccess: flow(currentStore.action.fetchSuccess, put),
    updateSession: flow(currentStore.action.updateSession, put),
    servicePostMessage: flow(currentStore.action.servicePostMessage, put),
  };

  const descriptorMax22 = detail.descriptor.substring(0, 22);

  const dataSubObj = {
    '00': [dicoveryLen(descriptorMax22), removeAcentoUtil(descriptorMax22)],
    '01': [dicoveryLen(detail.endereco), removeAcentoUtil(detail.endereco)],
    '02': [dicoveryLen(detail.cidade), removeAcentoUtil(detail.cidade)],
    '03': [dicoveryLen(detail.uf), detail.uf],
    '04': ['03', ' BR'],
    '05': [dicoveryLen(detail.cep), detail.cep],
    '06': [dicoveryLen(detail.mcc), detail.mcc.padStart(4, '0')],
    '07': [dicoveryLen(detail.nuCPFCNPJ), detail.nuCPFCNPJ],
    '08': ['11', detail.nuTelefone.padStart(11, '0')],
    '09': [dicoveryLen(detail.tid), detail.tid],
    '10': [dicoveryLen(detail.email), detail.email],
    '11': [
      dicoveryLen(detail.nmRazaoSocial),
      removeAcentoUtil(detail.nmRazaoSocial),
    ],
    '12': ['01', detail.nuCPFCNPJ.length > 11 ? 'J' : 'F'],
    '13': ['15', detail.mid.padStart(15, '0')],
  };

  const paramsClient = `[ParmsClient=1=${detail.nuCPFCNPJ};2=23041219000134]`;
  const paramsSub = `[DadosSubAdquirencia=${converToStr(dataSubObj)}]`;
  const paramsPort = `[PortaPinPad=${detail.portaCom}]`;

  const initParams = [paramsPort, paramsClient, paramsSub].join(';');

  const sessionParameters = initParams;

  const sitef = '127.0.0.1';
  const empresa = String(detail.idLoja);
  const terminal =
    String(detail.cdCliente).substring(0, 4) +
    String(detail.nuCPFCNPJ).substring(0, 4);

  const args = {
    sitefIp: sitef,
    storeId: empresa,
    terminalId: terminal,
    sessionParameters,
  };

  const request = {
    url: `${ROUTES.EXTERNAL.PINPAD}/session`,
    type: 'post',
    data: args,
  };

  try {
    yield handlers.fetchStart();

    const [success, result] = yield call(fetchAgent, request);

    if (!success) {
      throw result;
    }

    const { sessionId } = result;

    yield handlers.fetchSuccess(result);

    yield handlers.updateSession({
      sessionId,
    });

    yield handlers.servicePostMessage();
  } catch (e) {
    yield handlers.fetchError(e);
  }
};

const handleServicePostMessage = function* (action) {
  const { sessionId } = yield select(currentStore.selector.selectSession);

  const args = {
    sessionId,
    displayMessage: 'Giro Pagamentos',
    persistent: 'Y',
  };

  const request = {
    url: `${ROUTES.EXTERNAL.PINPAD}/pinpad/setDisplayMessage`,
    type: 'post',
    data: args,
  };

  yield call(fetchAgent, request);
};

function* watch() {
  yield takeEvery(ACTION_TYPES.SERVICE.POST_START, handleServicePostStart);
  yield takeEvery(ACTION_TYPES.SERVICE.POST_FINISH, handleServicePostFinish);
  yield takeEvery(
    ACTION_TYPES.SERVICE.POST_CONTINUE,
    handleServicePostContinue
  );
  yield takeEvery(
    ACTION_TYPES.SERVICE.POST_NEW_SESSION,
    handleServicePostNewSession
  );
  yield takeEvery(
    ACTION_TYPES.SERVICE.POST_IS_PRESENT,
    handleServicePostIsPresent
  );
  yield takeEvery(ACTION_TYPES.SERVICE.POST_MESSAGE, handleServicePostMessage);
}

export default {
  watch,
};
