import { flow } from 'lodash';
import { takeLatest, put, select, delay, call, take } from 'redux-saga/effects';

import postApiTransactions from '@giro/shared-services/pdv/postApiTransactions.service';

import dialogPay from '../dialogs/dialogPay';

import toaster from '@giro/shared-store/toaster';

import currentStore from '.';

import auth from '@giro/shared-store/auth';

import tablePayment from '../tablePayment';
import clients from '../clients';
import pinpad from '../pinpad';
import debts from '../debts/debts';

import segmentNormalizeConstant from '@giro-pdv/constants/segmentNormalize.constant';

const parserBySegment = (segment, values) => {
  if (segment == segmentNormalizeConstant.VEICULOS) {
    return {
      DsMarca: values?.company,
      DsModelo: values?.model,
      DsPlacaRenavam: values?.plate,
      NmCliente: values?.name,
      NuAnoModelo: values?.model,
      NuCPF: values?.cpf,
      NuRG: values?.rg,
      NuTelefone: values?.phone,
      Valor: values?.total || 0,
    };
  }

  if (
    segment == segmentNormalizeConstant.GERAL ||
    segment == segmentNormalizeConstant.EDUCACAO ||
    segment == segmentNormalizeConstant.CEMITERIO
  ) {
    return {
      dsIdentificacaoCliente: values?.client,
      vlMaterial: values?.material || 0,
      vlServicos: values?.service || 0,
    };
  }

  if (segment == segmentNormalizeConstant.ACESSORIA) {
    return {
      dsIdentificacaoCliente: values?.client,
      cdBarras: values?.cdBarras,
      valor: values?.total || 0,
    };
  }

  if (segment == segmentNormalizeConstant.DESPACHANTE) {
    return {
      cdVendaDespachante: null, // controla na api
      dsPlacaRenavam: values?.plate,
      dsTipoServico: values?.type,
      nmTitular: null, // controla na api
      nuCPFCNPJ: values?.document,
      nuRegistro: values?.registry,
      vlHonorarios: values?.fees || 0,
      vlTaxas: values?.tax || 0,
    };
  }

  if (segment == segmentNormalizeConstant.BOLETO) {
    return {
      cdBarras: values?.debt?.barcode,
      contribuinte: '',
      referencia: values?.debt?.description,
      tipo: values?.debt?.type,
      valor: values?.debt?.amount,
      vencimento: values?.debt?.due_date,
    };
  }

  if (segment == segmentNormalizeConstant.RECHARGE) {
    return {
      operadoraId: values?.operator,
      valor: values?.total,
      numeroTelefone: values?.phone_number,
      ddd: values?.ddd,
      produtoId: values?.produtoId,
      historicoId: values?.historicoId,
    };
  }

  if (segment == segmentNormalizeConstant.DETRAN) {
    return values?.debt?.map((d) => ({
      cdCliente: values?.cdCliente,
      cdTransacao: values?.cdTransacao,
      cdOrgaoDetentor: 6,
      cDTipoTributo: 0,
      cpfCnpj: d?.document,
      dataVencimento: d?.due_date,
      descricaoTributo: d?.description,
      codigoBarras: d?.reference.split(';')[2],
      nomeProprietario: d?.owner,
      placa: d?.plate,
      renavam: values?.renavam,
      valor: d?.amount,
    }));
  }

  if (segment == segmentNormalizeConstant.CARTORIO) {
    return {
      dsIdentificacaoCliente: values?.client,
      vlServicos: values?.service || 0,
      SenhaAtendimento: values?.att_password || '',
    };
  }
};

function* handleServicePost(action) {
  const debtsState = yield select(debts.selector.selectState);
  const clientsState = yield select(clients.selector.selectPayload);
  const tablePaymentState = yield select(tablePayment.selector.selectState);

  const user = yield select(auth.selector.selectUser);

  const { payload } = action;

  const {
    segment,
    isSend,
    isPix,
    isTerminal,
    onSucess,
    sendObj,
    ...restPayload
  } = payload;

  const showToaster = flow(toaster.action.show, put);

  const { retornoGiro } = clientsState || {};

  const value_return =
    tablePaymentState.return === 0
      ? 0
      : ((tablePaymentState.amount * tablePaymentState.return) / 100).toFixed(
          2
        );

  const isPaymentCredit =
    tablePaymentState?.installmentSelected?.type === 'Crédito';

  let payloadToSend: any = {
    CdTransacao: null,
    CdAmbienteOperadora: 2, // fixo
    CdCliente: user.user.codes.idClient,
    CdUsuario: user.user.codes.idUser,
    CdInterface: 3, // fixo
    CdSegmento: segment,
    CdTipoCusto: null, // fixo
    CdTipoPagamento:
      isPix || tablePaymentState?.installmentSelected?.type === 'PIX'
        ? 3
        : isPaymentCredit
        ? 2
        : 1,
    CdTipoTransacao: tablePaymentState.typeTax === 'client' ? 1 : 2,
    DsCardHolder: null,
    DsBinCartao: null,
    CdBandeira: null,
    QtParcelas: isPaymentCredit
      ? tablePaymentState?.installmentSelected?.installment
      : 1,
    VlTransacao:
      restPayload?.type_tax === 'client'
        ? Number(
            tablePaymentState?.installmentSelected?.amount_number || 0
          ).toFixed(2)
        : tablePaymentState.amount,
    VlLiberado:
      restPayload?.type_tax === 'client'
        ? String(Number(tablePaymentState.amount) + Number(value_return))
        : Number(tablePaymentState?.installmentSelected?.amount_number).toFixed(
            2
          ),
    CdAdquirente: clientsState.acquirerId,
    VlParcela: Number(
      tablePaymentState?.installmentSelected?.installment_amount_number || 0
    ).toFixed(2),
    IdTransacao: null,
    DsRetorno: `R${tablePaymentState.return}`,
    VlRetorno: value_return,
    DtQuitacao: null,
    CdStatusTransacao: 2, // fixo
    DsMensagemTransacao: null,
    CdTabTaxa: null,
    PdvVersion: null,
    Venda: {
      CdTransacao: 0,
      CdSegmento: segment,
      VendaDespachante: null,
      VendaGeral: null,
      VendaVeiculo: null,
      VendaTributo: null,
      VendaDetran: null,
      VendaHospital: null,
      VendaAssessoria: null,
      Pagamentos: null,
      VendaCrea: null,
    },
  };

  if (isPix) {
    payloadToSend = {
      ...payloadToSend,
      VlTransacao: tablePaymentState.amount,
      createPix: true,
    };
  }

  if (isSend) {
    payloadToSend = {
      ...payloadToSend,
      CdStatusTransacao: 35,
      link: sendObj,
    };
  }

  if (retornoGiro && Number(tablePaymentState?.return) > 0) {
    const tableAmount = Number(tablePaymentState.amount);
    const returnEc = Number(tablePaymentState.return);

    const returnPlusGiro = tablePaymentState?.return >= 8 ? 0.5 : 0;
    const returnGiro = returnEc * 0.25 + returnPlusGiro;
    const returnTotal = returnEc + returnGiro;
    const valueReturnTotal = (tableAmount * returnTotal) / 100;
    const valueReturnGiro = (returnGiro / returnTotal) * valueReturnTotal;
    const valueReturnEc = (returnEc / returnTotal) * valueReturnTotal;

    const VlLiberado =
      restPayload?.type_tax === 'client'
        ? Number(tableAmount + valueReturnEc).toFixed(2)
        : Number(tablePaymentState?.installmentSelected?.amount_number).toFixed(
            2
          );

    payloadToSend = {
      ...payloadToSend,
      VlRetornoGiro:
        restPayload?.type_tax === 'client'
          ? Number(valueReturnGiro).toFixed(2)
          : '0',
      VlRetorno:
        restPayload?.type_tax === 'client'
          ? Number(valueReturnEc).toFixed(2)
          : '0',
      VlLiberado,
    };
  }

  if (!isPaymentCredit && Number(tablePaymentState?.return) > 0) {
    payloadToSend = {
      ...payloadToSend,
      VlLiberado:
        restPayload?.type_tax === 'client'
          ? Number(tablePaymentState.amount).toFixed(2)
          : Number(
              tablePaymentState?.installmentSelected?.amount_number
            ).toFixed(2),
    };

    delete payloadToSend.VlRetorno;
    delete payloadToSend.VlRetornoGiro;
  }

  if (segment == segmentNormalizeConstant.VEICULOS) {
    const dataObj = {
      Conta: user.user.codes.idClient,
      CdTransacao: 0,
      ...parserBySegment(segment, restPayload),
    };

    payloadToSend.Venda.VendaVeiculo = dataObj;
  }

  if (segment == segmentNormalizeConstant.EDUCACAO) {
    const dataObj = {
      cdCliente: user.user.codes.idClient,
      cdTransacao: 0,
      ...parserBySegment(segment, restPayload),
    };

    payloadToSend.Venda.VendaEducacao = dataObj;
  }

  if (segment == segmentNormalizeConstant.GERAL) {
    const dataObj = {
      cdCliente: user.user.codes.idClient,
      cdTransacao: 0,
      ...parserBySegment(segment, restPayload),
    };

    payloadToSend.Venda.VendaGeral = dataObj;
  }

  if (segment == segmentNormalizeConstant.CEMITERIO) {
    const dataObj = {
      cdCliente: user.user.codes.idClient,
      cdTransacao: 0,
      ...parserBySegment(segment, restPayload),
    };

    payloadToSend.Venda.VendaCemiterio = dataObj;
  }

  if (segment == segmentNormalizeConstant.ACESSORIA) {
    const dataObj = {
      cdCliente: user.user.codes.idClient,
      cdTransacao: 0,
      ...parserBySegment(segment, restPayload),
    };

    payloadToSend.Venda.VendaAssessoria = dataObj;
  }

  if (segment == segmentNormalizeConstant.DESPACHANTE) {
    const dataObj = {
      cdCliente: user.user.codes.idClient,
      cdTransacao: 0,
      ...parserBySegment(segment, restPayload),
    };

    payloadToSend.Venda.VendaDespachante = dataObj;
  }

  if (segment == segmentNormalizeConstant.DETRAN) {
    const dataObj = parserBySegment(segment, {
      ...restPayload,
      cdCliente: user.user.codes.idClient,
      cdTransacao: 0,
      debt: debtsState?.selected,
    });
    payloadToSend.Venda.VendaDetran = dataObj;
  }

  if (segment == segmentNormalizeConstant.BOLETO) {
    const dataObj = {
      cdCliente: user.user.codes.idClient,
      cdTransacao: 0,
      ...parserBySegment(segment, {
        ...restPayload,
        debt: debtsState?.data?.results?.[0],
      }),
    };

    payloadToSend.Venda.VendaTributo = dataObj;
  }

  if (segment == segmentNormalizeConstant.RECHARGE) {
    const dataObj = {
      cdCliente: user.user.codes.idClient,
      cdTransacao: 0,
      ...parserBySegment(segment, restPayload),
    };

    payloadToSend.Venda.VendaRecargaCelular = dataObj;
  }

  if (segment == segmentNormalizeConstant.CARTORIO) {
    const dataObj = {
      cdCliente: user.user.codes.idClient,
      cdTransacao: 0,
      ...parserBySegment(segment, restPayload),
    };

    payloadToSend.Venda.VendaCartorio = dataObj;
  }

  try {
    yield put(currentStore.action.updateCurrent(payloadToSend));

    yield put(currentStore.action.fetchStart());

    const [success, result] = yield call(postApiTransactions, payloadToSend);

    if (!success) {
      throw result;
    }

    yield put(currentStore.action.fetchSuccess(result));

    (window as any).clarity('set', 'cdTransacao', String(result?.meta));

    if (!isSend && !isPix && !isTerminal) {
      yield put(
        pinpad.action.updateSession({
          cupomFiscal: result?.meta,
        })
      );

      yield put(dialogPay.action.reset());

      yield put(dialogPay.action.open(payload));
    }

    if (onSucess) {
      const state = yield select(currentStore.selector.selectState);

      onSucess(result, state);
    }
  } catch (e) {
    yield put(currentStore.action.fetchError());

    yield showToaster({
      message: 'Aconteceu um erro, tente novamente mais tarde',
      variant: 'error',
    });
  }
}

function* handleServicePatch(action) {
  const currentData = yield select(currentStore.selector.selectCurrent);
  const data = yield select(currentStore.selector.selectData);
  const session = yield select(pinpad.selector.selectSession);
  const dataPinpad = yield select(pinpad.selector.selectData);

  const fields = session?.ret;

  const showToaster = flow(toaster.action.show, put);

  const lastData = dataPinpad?.slice(-1)?.pop();

  const newCurrentData = {
    ...currentData,
    DsMensagemTransacao: String(lastData?.clisitefStatus || '0'),
    CdTransacao: data?.meta,
    CdStatusTransacao: action.payload,
    DsBinCartao: fields?.find((f) => f.TipoCampo === 2021)?.Valor,
    DsCardHolder: fields?.find((f) => f.TipoCampo === 1003)?.Valor,
    IdTransacao: fields?.find((f) => f.TipoCampo === 134)?.Valor,
  };

  yield put(currentStore.action.updateCurrent(newCurrentData));

  try {
    yield put(currentStore.action.fetchStart());

    const [success, result] = yield call(postApiTransactions, newCurrentData);

    if (!success) {
      throw result;
    }

    yield put(currentStore.action.fetchSuccess(result));
  } catch (e) {
    yield put(currentStore.action.fetchError());

    yield showToaster({
      message: 'Aconteceu um erro, tente novamente mais tarde',
      variant: 'error',
    });
  }

  if (action.payload === 3) {
    yield put(pinpad.action.updateTransactionStatus('transaction_approved'));
  } else {
    yield put(pinpad.action.updateTransactionStatus('transaction_rejected'));
  }
}

function* watch() {
  yield takeLatest(
    currentStore.constant.ACTION_TYPES.SERVICE.POST,
    handleServicePost
  );
  yield takeLatest(
    currentStore.constant.ACTION_TYPES.SERVICE.PATCH,
    handleServicePatch
  );
}

export default {
  watch,
};
